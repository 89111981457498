.elevation-gizmo {
  box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 25%);
}

.elevation-0 {
  box-shadow: none !important;
}

.elevation-1 {
  transition: box-shadow .2s ease-in;

  box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.03);

  &:focus {
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.03);
  }

  &.elevation-hover {
    &:hover {
      box-shadow: 1px 2px 4px 0 rgba(0,0,0,0.06),  1px 1px 2px 0 rgba(0,0,0,0.06);
    }
  }
}

.elevation-2 {
  box-shadow:
    0 0 0 1px rgba(15, 15, 15, 0.05),
    0 1px 2px rgba(15, 15, 15, 0.05),
    0 3px 8px rgba(15, 15, 15, 0.1)
}

.elevation-3 {
  box-shadow:
    0 0 0 1px rgba(15, 15, 15, 0.05),
    0 2px 4px rgba(15, 15, 15, 0.075),
    0 7px 18px rgba(15, 15, 15, 0.15)
}

.elevation-3-overlay {
  box-shadow:
      0 0 0 1px rgba(15, 15, 15, 0.05),
      0 2px 4px rgba(15, 15, 15, 0.075),
      0 7px 18px rgba(15, 15, 15, 0.15),
      0px 0px 0px 9999px rgba(0, 0, 0, 0.15)
}

.elevation-4 {
  box-shadow:
    0 0 0 1px rgba(15,15,15,.05),
    0 3px 6px rgba(15,15,15,.1),
    0 9px 24px rgba(15,15,15,.2);
}
