$tooltip-gap: 8px;
$tooltip-border-radius: 3px;
$tooltip-background-color: #000;
$tooltip-color: #fff;
$tooltip-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
$tooltip-arrow-size: 7px;
$tooltip-padding: 5px 10px;

*[data-tooltip-text]:not(.tooltip-disabled) {
  position: relative;

  &[data-tooltip-position='top'] {
    &::before,
    &::after {
      left: 50%;
      bottom: 100%;
      transform: translateX(-50%);
    }

    &::before {
      margin-bottom: $tooltip-gap;
    }

    &::after {
      margin-bottom: $tooltip-gap - $tooltip-arrow-size;
      border-color: $tooltip-background-color transparent transparent transparent;
      border-bottom-width: 0;
    }
  }

  &.tooltip-left-skew {
    &::before,
    &::after {
      left: 75%;
      transform: translateX(-75%);
    }
  }

  &.tooltip-right-skew {
    &::before,
    &::after {
      left: 25%;
      transform: translateX(-25%);
    }
  }

  &[data-tooltip-position='right'] {
    &::before,
    &::after {
      left: 100%;
      top: 50%;
      transform: translateY(-50%);
    }

    &::before {
      margin-left: $tooltip-gap;
    }

    &::after {
      margin-left: $tooltip-gap - $tooltip-arrow-size;
      border-color: transparent $tooltip-background-color transparent transparent;
      border-left-width: 0;
    }
  }

  &[data-tooltip-position='bottom'] {
    &::before,
    &::after {
      left: 50%;
      top: 100%;
      transform: translateX(-50%);
    }

    &::before {
      margin-top: $tooltip-gap;
    }

    &::after {
      margin-top: $tooltip-gap - $tooltip-arrow-size;
      border-color: transparent transparent $tooltip-background-color transparent;
      border-top-width: 0;
    }
  }

  &[data-tooltip-position='left'] {
    &::before,
    &::after {
      right: 100%;
      top: 50%;
      transform: translateY(-50%);
    }

    &::before {
      margin-right: $tooltip-gap;
    }

    &::after {
      margin-right: $tooltip-gap - $tooltip-arrow-size;
      border-color: transparent transparent transparent $tooltip-background-color;
      border-right-width: 0;
    }
  }

  &[data-tooltip-position='bottom'], &[data-tooltip-position='top'] {
    &.tooltip-start {
      &::before {
        left: 0%;
        transform: translateX(0px);
      }

      &::after {
        left: 0%;
        transform: translateX(5px);
      }
    }

    &.tooltip-end {
      &::before {
        right: 0%;
        left: auto;
        transform: translateX(0px);
      }

      &::after {
        right: 0%;
        left: auto;
        transform: translateX(-5px);
      }
    }
  }

  &[data-tooltip-position='left'], &[data-tooltip-position='right'] {
    &.tooltip-start {
      &::before {
        top: 0%;
        transform: translateY(0px);
      }

      &::after {
        top: 0%;
        transform: translateY(5px);
      }
    }

    &.tooltip-end {
      &::before {
        top: auto;
        bottom: 0%;
        transform: translateY(0px);
      }

      &::after {
        top: auto;
        bottom: 0%;
        transform: translateY(-5px);
      }
    }
  }

  &::before,
  &::after {
    position: absolute;
    z-index: 10000;
    pointer-events: none;

    opacity: 0;
    transition: opacity 100ms ease-in-out;
  }

  &::before {
    content: attr(data-tooltip-text);
    // reset styles bleeding in
    text-transform: none;
    text-shadow: none;

    background-color: $tooltip-background-color;
    color: $tooltip-color;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.4;
    box-shadow: $tooltip-shadow;
    border-radius: $tooltip-border-radius;
    padding: $tooltip-padding;
    text-align: center;
    max-width: 200px;
    inline-size: max-content;
    white-space: normal;
  }

  &::after {
    content: '';

    border: $tooltip-arrow-size solid $tooltip-background-color;
  }

  &:hover {
    &::before,
    &::after {
      opacity: 1;
    }
  }
}
