@import "../custom/grid";
@media (max-width: map-get($grid-breakpoints, "lg")) {
  .modal-sm-fullscreen {
    width: 100%;
    height: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0;
    border: 0 none;
    border-radius: 0;

    .modal-content {
      height: auto;
      min-height: 100%;
      border-radius: 0;
      border: 0 none;
    }

    &.modal-dialog-scrollable {
      max-height: 100vh;

      .modal-content {
        max-height: 100vh;
      }
    }
  }
}
