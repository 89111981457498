@import "../custom/colors";
@import "../custom/elevations";
@import "../custom/variables";

.btn-gray {
  color: $gray-400;
  background: transparent;

  &:hover, &:focus, &:active, &.focus, &.active {
    color: $gray-600;
  }
}

.btn-white {
  background: $white;
  border: 1px solid $gray-300;

  &:hover, &:focus, &:active, &.focus, &.active {
    background: darken($white, 2%);
  }
}

.btn-danger {
  // Overwrite the color of btn-danger because the internal function (color-yiq) of bootstrap returns a dark color for our danger-bg-color.
  color: #fff;
}

// Use in conjunction with other btn-link class
.btn-underline {
  position: relative;

  &.active {
    color: $blue;
    font-weight: 500;

    &:after {
      content: '';
      position: absolute;
      bottom: -1px;
      left: map-get($spacers, 2);
      width: calc(100% - #{map-get($spacers, 2)} * 2);
      height: 3px;
      background: $blue;
    }
  }
}
