@import "variables"; // Custom Variables lay here

$color-light-level: -5;
$color-dark-level: 5;
$bg-light-level: -10;
$bg-dark-level: 9;
$border-light-level: -4;
$border-dark-level: 8;

$border-radius-xl: 4px;

@function color-level($color-name: "blue", $level: 0) {
  $color: color($color-name);
  $color-base: if($level > 0, $black, $white);
  $level: abs($level);

  @return mix($color-base, $color, $level * $theme-color-interval);
}

@mixin badge($color) {
  background: color-level($color, $bg-light-level);
  color: color-level($color, 1);
}

@mixin badge_border($color) {
  background: color-level($color, $bg-light-level);
  color: map_get($colors, $color);
  border-color: color-level($color, $border-light-level)
}

@mixin focus-styles() {
  box-shadow: var(--focus-shadow, none);
  outline: none;

  @media (forced-colors: active) {
    outline: 2px solid black;
  }
}

@mixin btn-focus-styles($color: null) {
  @if($color) {
    box-shadow: $color;
  } @else {
    box-shadow: var(--btn-focus-shadow, none);
  }

  outline: none;

  @media (forced-colors: active) {
    outline: 2px solid black;
  }
}

@keyframes arrow {
  0% {
    transform: translateX(-150%);
  }

  60% {
    transform: translateX(-250%);
  }

  100% {
    transform: translateX(-150%);
  }
}

//@each $color, $value in $colors {
//  .#{$color} {
//    color: $value;
//    &-light {
//      color: color-level($color, $color-light-level);
//
//      &-bg {
//        background-color: color-level($color, $bg-light-level);
//      }
//      &-border {
//        border-color: color-level($color, $border-light-level);
//      }
//    }
//    &-dark {
//      color: color-level($color, $color-dark-level);
//
//      &-bg {
//        background-color: color-level($color, $bg-dark-level);
//      }
//      &-border {
//        border-color: color-level($color, $border-dark-level);
//      }
//    }
//
//  }
//}
