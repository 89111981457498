@import "extend";

$token-bg-color: #fff;
$token-border-color: #ddd;
$token-accent-color: #70BF8E;


// In printOptimized mode, we show a black underline for empty tokens
.variable-placeholder {
  color: #fff;
  border-bottom: 1px solid #000000;
  // word-break: break-all; might fix problems with long variable names
}

// Within PDF based buckets, we hide empty tokens in printOptimized mode
pl-pdf-part-child .variable-placeholder {
  opacity: 0;
}

.variable {
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  text-align: inherit;
  transition: none;
  position: initial;
  border-radius: 3px;
  display: inline;
  padding: 1px 3px;
  cursor: pointer;
  word-break: break-word;
  white-space: normal;
  -webkit-box-decoration-break: clone;

  &.has-value {
    background-color: transparentize($token-accent-color, 0.85);
    border-bottom: 2px solid transparentize($token-accent-color, 0.25);

    &.highlight {
      z-index: 2;
      animation: highlightWithValue 500ms ease-in-out ;
    }
  }

  &.no-value {
    background-color: transparentize($warning, 0.2);
    color: #000;

    &.highlight {
      z-index: 2;
      animation: highlightWithoutValue 500ms ease-in-out ;
    }
  }

  &.missing {
    background-color: $danger;
    color: #fff;
  }

  @keyframes highlightWithValue {
    from {
      box-shadow: none;
    }
    35% {
      box-shadow: 0 0 0 5px transparentize($token-accent-color, 0.25);
    }
    to {
      box-shadow: none;
    }
  }

  @keyframes highlightWithoutValue {
    from {
      box-shadow: none;
    }
    35% {
      box-shadow: 0 0 0 5px transparentize($warning, 0.2);
    }
    to {
      box-shadow: none;
    }
  }
}
