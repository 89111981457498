@import "../../styles/custom/variables";

.p-wrapper {
  width: 100%;
  overflow: hidden;

  .p-percent {
    font-size: 18px;
    color: $gray-400;
    text-align: center;
    position: absolute;
    width: 100%;
    left: 0;

    &::after {
      content: '%';
    }
  }

  .progress-bar {
    height: 1.8rem;
    background-color: $gray-700;
    border-radius: 2px;
    transition: width 0.3s ease;
  }
}
