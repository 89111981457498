@import "extend";

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-top-left {
  top: map-get($spacers, 4);
  left: map-get($spacers, 4);
}

.toast-top-right {
  top: map-get($spacers, 4);
  right: map-get($spacers, 4);
}

.toast-bottom-right {
  right: map-get($spacers, 4);
  bottom: map-get($spacers, 4);
}

.toast-bottom-left {
  bottom: map-get($spacers, 4);
  left: map-get($spacers, 4);
}

.toast-success {
  background-image: url("data:image/svg+xml,%3Csvg width='512' height='512' viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle fill='%23FFF' cx='256' cy='256' r='256'/%3E%3Cpath d='M358.327 175.062L221.828 305.673l-50.155-47.991c-2.431-2.327-6.374-2.327-8.806 0l-14.677 14.044c-2.432 2.327-2.432 6.1 0 8.427l69.235 66.248c2.431 2.327 6.374 2.327 8.806 0l155.58-148.868c2.431-2.327 2.431-6.1 0-8.427l-14.678-14.044c-2.431-2.327-6.374-2.327-8.806 0z' stroke='%238792A1' stroke-width='6.244' fill='%238792A1' fill-rule='nonzero'/%3E%3C/g%3E%3C/svg%3E");
}
