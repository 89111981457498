@import "../../../../node_modules/bootstrap/scss/forms";

select.select {
  appearance: none;
  border: 0;
  background-color: transparent;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxNHB4IiBoZWlnaHQ9IjdweCIgdmlld0JveD0iMCAwIDE0IDciIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+ICAgICAgICA8dGl0bGU+UGF0aCA3PC90aXRsZT4gICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+ICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgaWQ9IkRlc2t0b3AtQ29weS00NiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEzMDMuMDAwMDAwLCAtMjcxLjAwMDAwMCkiIGZpbGw9IiNBQUFGQjUiPiAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJQYXRoLTciIHBvaW50cz0iMTMwMyAyNzEgMTMxMC4wMTM0MSAyNzggMTMxNyAyNzEiPjwvcG9seWdvbj4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 10px;
  padding-right: 15px;
}

.borderless-form-control {
  @extend .form-control;

  border: none;
  border-radius: 0;
  box-shadow: none;
}
