
@import "../../../../node_modules/bootstrap/scss/functions";
@import "variables";
@import "../../../../node_modules/bootstrap/scss/mixins";

@mixin pl-button-variant(
  $color,
  $color-hover: darken($color, 10),
  $background: transparentize($color, .85),
  $active-background: transparentize(darken($color, 10), .75),
  $active-border: $color,
  $active-color: $color
) {
  color: $color;
  border-color: $color;
  background-color: $background;

  &:hover {
    color: $color-hover;
    background-color: $active-background;
    border-color: $active-border;
  }

  .btn-check:checked + &,
  .btn-check:active + &,
  &:active,
  &.active,
  &.dropdown-toggle.show {
    color: $active-color;
    background-color: $active-background;
    border-color: $active-border;
    z-index: 1;
  }

  &:disabled,
  &.disabled {
    pointer-events: none;
  }
}

$transparent: transparent;

@each $color, $value in ("primary": map-get($theme-colors, "primary"), "lark": map-get($theme-colors, "lark")){
  .btn-pl-#{$color} {
    @include pl-button-variant($value);
  }
}

@each $color, $value in ("secondary": map-get($theme-colors, "secondary"), "danger": map-get($theme-colors, "danger"), "lark": map-get($theme-colors, "lark")){
  .btn-pl-outline-#{$color} {
    @include pl-button-variant(
        $value,
        darken($value, 10),
        transparent,
        transparentize($value, .75),
        $value,
        $value
    );
  }
}
