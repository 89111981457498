@import "utils/bootstrap";
@import "classes/button";
@import "classes/forms";
@import "classes/progress";
@import "classes/modal";
@import "classes/user-avatar";
@import "custom/elevations";
@import "custom/toastr";
@import "custom/token_variables";
@import "custom/colors";
@import "custom/tooltips";
@import "custom/gap";

@import "@angular/cdk/overlay-prebuilt.css";

legend {
  font-size: 1rem;
  font-weight: 400;
}

.cdk-overlay-container {
  z-index: 2000;
}

.overlay-backdrop {
  transition: none;
  background: rgba(0, 0, 0, .5);
}

.overlay-panel {
  background: #fff;
}


html,
body {
  overflow: hidden;
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-overflow-scrolling: touch;

  &.modal-open {
    -webkit-overflow-scrolling: auto;
  }
}

mark {
  background: transparent;
  padding: 0;
}

label, .font-weight-500 {
  font-weight: 500;
}

.line-height-1 {
  line-height: 1;
}

.form-check {
  label {
    font-weight: 400;
  }
}
.dropdown-toggle::after {
  vertical-align: 3px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid;
}

.dropdown-menu {
  outline: 1px solid transparent;

  pl-icon {
    display: inline-block;
    width: 1.25em;
    vertical-align: text-top;
  }

  &>.dropdown-divider:last-child {
    display: none;
  }
}

// FOCUS STYLES
.form-control, .custom-select {
  transition: $input-transition;
  border-radius: $input-border-radius;

  &:focus {
    @include focus-styles();
  }
}

.custom-radio .custom-control-input,
.custom-checkbox .custom-control-input {
  &[readonly],
  .readonly {
    cursor: none;
    user-select: none;
    pointer-events: none;

    // necessary to prevent clicks/changes in document single view (inputs are only readonly and not disabled
    ~ .custom-control-label {
      cursor: none;
      user-select: none;
      pointer-events: none;

      &::before,
      &::after {
        cursor: none;
        user-select: none;
        pointer-events: none;
      }
    }
  }

  &:disabled:checked {
    ~ .custom-control-label {
      &::before {
        border-color: $gray-500;
        background-color: $gray-500;
      }
    }
  }

  &:checked ~ .custom-control-label:before {
    color: #fff;
    border-color: #0083F6;
    background-color: #0083F6;
  }

  &:focus ~ .custom-control-label:before {
    @include focus-styles();
  }
}

#skippy {
  &:focus span {
    @include focus-styles();
  }
}

.btn {
  &:focus,
  &.focus {
    outline: unset;
    box-shadow: unset;

    @include btn-focus-styles();
  }
}
// END FOCUS STYLES


// because we set valid color to grey (to prevent green input borders), we have to manually override the color for the valid state of
// custom inputs here
.was-validated {
  .custom-radio .custom-control-input,
  .custom-checkbox .custom-control-input {
    &:checked ~ .custom-control-label::before {
      color: #fff;
      border-color: #0083F6;
      background-color: #0083F6;
    }
  }
}

.maxlength-indicator {
  color: $gray-500;
}

.block-selection--overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  transition: 0.15s;
  opacity: 0;

  &:hover {
    opacity: 0.35;
    cursor: not-allowed;
  }

  &-0 {
    background-color: $user-color-0;
  }

  &-1 {
    background-color: $user-color-1;
  }

  &-2 {
    background-color: $user-color-2;
  }

  &-3 {
    background-color: $user-color-3;
  }

  &-4 {
    background-color: $user-color-4;
  }

  &-5 {
    background-color: $user-color-5;
  }

  &-6 {
    background-color: $user-color-6;
  }

  &-7 {
    background-color: $user-color-7;
  }

  &-8 {
    background-color: $user-color-8;
  }
}

svg {
  transform: translateZ(0);
}

pre.line-numbering {
  padding: 0.5rem 2.5rem;

  div {
    display: inline-block;
  }

  div:before {
    counter-increment: line;
    content: counter(line);
    display: inline-block;
    padding: 0 .5em;
    margin-right: .5em;
    color: $gray-500;
    position: absolute;
    left: 0;
    z-index: 1;
    text-align: end;
  }

  .json-key {
    color: $gray-700;
  }

  .json-value {
    color: $blue;
  }

  .json-string {
    color: $green;
  }
}

app-list .empty-text {
  max-width: 420px;
}

@include media-breakpoint-up(xl) {
  .modal-lg2 { max-width: $modal-lg2; }
}

@include media-breakpoint-up(xl) {
  .modal-xxl { max-width: $modal-xxl; }
}

@include media-breakpoint-up(xl) {
  .modal-full { max-width: calc(100% - 3.5rem); }
}

.hide-when-empty:empty {
  display: none !important;
}
