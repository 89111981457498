$white:    #fff;
$gray-100: #FAFAFB;
$gray-150: #F1F1F3;
$gray-200: #ECEDED;
$gray-300: #DEE1E3;
$gray-400: #ced4da;
$gray-450: #ADB5BD;
$gray-500: #9fa2a6;
$gray-550: #898989;
$gray-600: #70777d;
$gray-700: #494e52;
$gray-800: #393d40;
$gray-900: #252729;
$black: #000;

$blue: #0083F6;
$indigo: #6610f2;
$purple: #9472CB;
$pink: #e83e8c;
$red: #dc3545;
$orange: #f1ba5b;
$yellow: #ffc107;
$green: #37A968;
$teal: #20c997;
$cyan: #28A9F8;
$light-red: #d78690;
$dark-blue: #05101A;

$user-color-0: #A13CB4;
$user-color-1: #1B887A;
$user-color-2: #FD573D;
$user-color-3: #A63232;
$user-color-4: #00A3BB;
$user-color-5: #F176A7;
$user-color-6: #63782F;
$user-color-7: #FF007A;
$user-color-8: #1FA15D;

$excel-green: #3f9638;
