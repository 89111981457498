@import "../../shared/styles/base";
@import "../../shared/styles/custom/pl-button";


.sketch-picker {
  padding: 0 !important;
  box-shadow: none !important;

  background: transparent;
}

.dropdown-menu {
  border: 0;
  min-width: 200px;
  top: 0;
}

.bs-datepicker {
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.2);
  transform: translate3d(198px, -38px, 0);
}

.btn.small {
  font-size: 15px;
}

dt {
  font-weight: normal;
  color: $gray-500;
}

.ng2-pdf-viewer-container {
  scrollbar-width: thin;
  scrollbar-gutter: stable;
  scrollbar-color: #ccc transparent;

  .pdfViewer {
    padding-top: map-get($spacers, 3);

    .page {
      @extend .elevation-2;
    }
  }
}

body.video {
  app-editor {
    display: block;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    app-header, app-page-list {
      display: none;
    }

    .bucket-wrapper {
      background: #EFF5FC;
      position: relative;
      z-index: 1;

      .bucket {
        max-height: 967px;
        min-height: 967px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
        position: relative;

        &:before {
          content: "";
          position: absolute;
          display: block;
          width: 100%;
          height: 100%;
          z-index: -1;
          background: white;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
          transform: translate3d(-10px, -10px, 10px);
        }

        @media (min-width: 1500px){
          height: 1301px !important;
          max-height: 1301px !important;
        }
      }
    }

    .block-margins > .children {
      padding-bottom: 0 !important;
    }

    .child-wrap {
      .right, .left {
        display: none !important;
      }
    }

    app-editor-panel {
      position: absolute;
      z-index: 2;
      right: 40px;
      top: 40px;
      width: 320px;
      border-left: 0 none;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      overflow: hidden;

      .scrollable {
        height: auto;
        padding-bottom: 0.75rem;
      }
    }

    app-editor-panel-navigation {
      display: none;
    }
  }
}

.badge-danger {
  background: mix($danger, white, 80);
}

.badge-success {
  background: mix($success, white, 80);
}

.alert-light {
  background: $gray-150;
  color: $gray-600
}

// use empty badge-light as block text within skeletons
.badge-light:empty {
  display: inline-block;
  height: 10px;
}

.drag-clone-container {
  .right {
    display: none !important;
  }
}

qrcode > div.qrcode {
  display: flex;
}

.form-control-otp {
  font-family: $font-family-monospace;
  font-size: 1.5rem;
}

a:focus-visible {
  outline: none;
}

// Chatwoot
#cw-bubble-holder {
  .woot-widget-bubble.woot-elements--right {
    right: 1rem !important;
    bottom: 1rem;
    height: 48px;
    width: 48px;
    background: $primary;

    svg {
      height: 20px;
      margin: 14px;
      width: 20px;
    }
  }

  .woot--close::before, .woot--close::after {
    height: 23px;
    left: 23px;
    top: 13px;
    width: 2px;
  }

  .woot-widget-bubble.woot--close {
    transform: translateX(0px) scale(1) rotate(0deg);
    transition: transform 300ms ease, opacity 100ms ease, visibility 0ms linear 0ms, bottom 0ms linear 0ms;
  }
  .woot-widget-bubble.woot--close.woot--hide {
    transform: translateX(0px) scale(1) rotate(45deg);
    transition: transform 300ms ease, opacity 200ms ease, visibility 0ms linear 500ms, bottom 0ms ease 200ms;
  }

  .woot-widget-bubble {
    transform-origin: center;
    will-change: transform, opacity;
    transform: translateX(0) scale(1) rotate(0deg);
    transition: transform 300ms ease, opacity 100ms ease, visibility 0ms linear 0ms, bottom 0ms linear 0ms;
  }
  .woot-widget-bubble.woot--hide {
    transform: translateX(0px) scale(1) rotate(-30deg);
    transition: transform 300ms ease, opacity 200ms ease, visibility 0ms linear 500ms, bottom 0ms ease 200ms;
  }
}

@media only screen and (min-width: 667px) {
  #cw-widget-holder {
    bottom: 80px;
  }
}
