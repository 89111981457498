@import "../custom/colors";

.user-avatar {
  display: inline-block;
  overflow: hidden;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid $gray-300;
}
